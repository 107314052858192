
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/slider/BasicUsage.vue";
import EUIDiscreteValues from "@/views/resources/documentation/element-ui/form/slider/DiscreteValues.vue";
import EUISliderWithInputBox from "@/views/resources/documentation/element-ui/form/slider/SliderWithInputBox.vue";
import EUIRangeSelection from "@/views/resources/documentation/element-ui/form/slider/RangeSelection.vue";
import EUIVerticalMode from "@/views/resources/documentation/element-ui/form/slider/VerticalMode.vue";
import EUIShowMarks from "@/views/resources/documentation/element-ui/form/slider/ShowMarks.vue";

export default defineComponent({
  name: "slider",
  components: {
    EUIBasicUsage,
    EUIDiscreteValues,
    EUISliderWithInputBox,
    EUIRangeSelection,
    EUIVerticalMode,
    EUIShowMarks
  },
  setup() {
    setCurrentPageTitle("Slider");
  }
});
